import React from "react"
import { motion } from "framer-motion"
import Img from "gatsby-image"

const HeroDesarrollos = props => {
  return (
    <motion.div
      initial={{ scale: 1.2 }}
      animate={{ scale: 1 }}
      transition={{ duration: 3 }}
    >
<Img fluid={props.fondo} alt="masterplan big" className="hidden lg:block h-screen content-center items-center bg-fixed" />
<Img fluid={props.fondosmall} alt="masterplan small" className="lg:hidden h-screen content-center items-center bg-fixed" />
    
    </motion.div>
  )
}

export default HeroDesarrollos
